/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

body {
    max-width: 1920px;
    margin: 0 auto;
}
.center {
    width: 1200px;
    margin: 0 auto;
}
.header {
    width: 100%;
    background: #f1f1f1;;
    position: relative;
    .top_top {
        widows: 100%;
        height: 45px;
        background: #fff;
        p {
            float: left;
            line-height: 45px;
            font-size: 14px;
            color: #666;
        }
    }
    .head_top {
        width: 100%;
        padding: 40px 0;
        .fl {
            a {
                float: left;
                img {
                    height: 60px;
                    display: block;
                }
            }
        }
        .fr {
            .tel {
                float: right;
                p {
                    padding-left: 55px;
                    background: url(../img/tel.jpg) no-repeat left center;
                    i {
                        font-size: 14px;
                        color: #666;
                        display: block;
                        font-style: normal;
                    }
                    span {
                        display: block;
                        color: #0071be;
                        font-size: 25px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
.pc_search {
    float: right;
    position: relative;
    margin-top: 10px;
    input {
        height: 26px;
        width: 240px;
        padding-right: 40px;
        border: none;
        border-radius: 20px;
        border: 1px solid #dfdfdf;
        outline: 0;
        padding-left: 10px;
    }
    .btn {
        height: 26px;
        width: 26px;
        position: absolute;
        right: 10px;
        top: 0;
        border: none;
        outline: 0;
        float: left;
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 30px;
        background: url(../img/seach1.png) no-repeat right center;
        background-size: 18px;
    }
    &:hover .btn {
        background: url(../img/seach2.png) no-repeat right center;
        background-size: 18px;
    }
}
.nav {
    width: 100%;
    background: #0071bf;
    height: 60px;
    ul {
        li {
            float: left;
            width: 11.11%;
            height: 60px;
            position: relative;
            a {
                width: 100%;
                text-align: center;
                line-height: 60px;
                font-size: 16px;
                color: #fff;
                display: block;
                &:hover {
                    background: #02b29c;
                }
                &.active {
                    background: #02b29c;
                }
            }
            .v_list {
                position: absolute;
                width: 120%;
                top: 60px;
                background: #02b29c;
                left: 0;
                z-index: 99;
                display: none;
                a {
                    color: #fff;
                    line-height: 36px;
                    font-size: 14px;
                    transition: .5s;
                    &:hover  {
                        transition: .5s;
                        background: #029e8b;
                    }
                }
            }
            &:hover .v_list {
                display: block;
            }
        }
    }
}
.fixed {
    position: fixed;
    max-width: 1920px;
    top: 0;
    z-index: 10000;
    float: none !important;
}

.pc_banner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .wappc_banner {
        .prev {
            left: 74px;
            width: 48px;
            height: 86px;
            position: absolute;
            top: 42%;
            background: url(../img/bannerle.png) no-repeat;
            background-size: 100%;
        }
        .next {
            right: 74px;
            width: 48px;
            height: 86px;
            position: absolute;
            top: 42%;
            background: url(../img/bannerri.png) no-repeat;
            background-size: 100%;
        }
        .hd {
            width: 100%;
            text-align: center;
            position: absolute;
            z-index: 9;
            bottom: 10px;
            overflow: hidden;
            li {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                display: inline-block;
                text-indent: -999px;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                background: #0071bf;
                margin: 0 3px;
                &.on {
                    background: #02b29c;
                }
            }
        }
        .bd {
            li {
                position: relative;
                img {
                    width: 1920px;
                    height: 610px;
                    position: relative;
                    left: 50%;
                    margin-left: -960px;
                }
            }
        }
    }
}
.m_banner {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: none;
    .mbanner {
        width: 100%;
        position: relative;
        ul {
            li {
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.banner_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 9;
    bottom: 10px;
    overflow: hidden;
    a {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        text-indent: -999px;
        overflow: hidden;
        margin: 0 3px;
        overflow: hidden;
        cursor: pointer;
        background: #eee;
        opacity: .4;
        margin: 0 5px;
        border: solid 2px #FFFFFF;
        &.in {
            background: #0071bf;
            opacity: 1;
        }

    }
}



.m_header {
    width: 100%;
    display: none;
    .m_head_logo {
        width: 100%;
        padding: 12px 1%;
        background: #f1f1f1;
        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    .m_head_content {
        height: .36rem;
        background: #0071bf;
        width: 100%;
        z-index: 99;
        box-shadow: 0px 5px 10px 1px #999;
        position: relative;
        span {
            font-size: 16px;
            color: #fff;
            float: left;
            line-height: .36rem;
            padding-left: 12px;
        }
        p {
            float: right;
            padding-right: .2rem;
            img {
                height: .16rem;
                margin-top: .1rem;
            }
        }
        .menu {
            float: right;
            width: .36rem;
            margin-right: .1rem;
            height: .36rem;
            i {
                color: #fff;
                font-size: 20px;
                text-align: center;
                line-height: .36rem;
                display: block;
            }
        }
    }
    .fixed {
        position: fixed;
    }   
}
.m_search {
    display: none;
    padding: 0 2%;
    width: 100%;
    position: absolute;
    background: #fff;
    top: .36rem;
    z-index: 100;
    .pc_search {
        width: 100%;
        margin: 12px 0;
        input {
            width: 100%;
            height: 36px;
        }
        .btn {
            width: 36px;
        }
    }
}
.app_menu {
    width: 100%;
    height: 500%;
    z-index: 99;
    display: none;
    position: absolute;
    top: .36rem;
    left: 0;
    ul {
        background: #0071bf;
        li {
            a {
                
                font-size: 14px;
                width: 100%;
                height: 36px;
                line-height: 36px;
                color: #fff;
                display: block;
                padding-left: .15rem;
                border-top: 1px solid #eee;
            }
        }
    }
}

.container {
    width: 100%;
    overflow: hidden;
}
.main {
    width: 100%;
    overflow: hidden;
}


.box_tit {
    strong {
        font-size: 38px;
        color: #333;
        display: block;
        font-weight: bold;
        text-align: center;
    }
    img {
        display: block;
        margin: 8px auto;
        max-width: 100%;
    }
    span {
        font-size: 18px;
        color: #b3b3b3;
        display: block;
        text-align: center;

    }
    &:hover {
        i {
            width: 68px;
            transition: 1s;
        }
    }
}


.main_l {
    width: 24%;
    background: #0071bf;
    padding-bottom: 20px;
    .text {
        width: 100%;
        padding: 20px 15px 0px 15px;
        span {
            color: #fff;
            font-weight: bold;
            font-size: 24px;
            display: block;
            margin-bottom: 10px;
        }
        p {
            display: block;
            font-size: 24px;
            color: #fff;
            margin-bottom: 30px;
            background: url(../img/jia.jpg) no-repeat right center;
        }
        ul {
            width: 100%;
            background: #fff;
            padding: 10px 15px 22px 15px;
            li {
                display: block;
                border-radius: 25px;
                a {
                    display: block;
                    text-decoration: none;
                    color: #fff;
                    font-size: 15px;
                    background: #0071bf;
                    border-radius: 25px;
                    padding: 6px 0px 6px 18px;
                    margin-bottom: 8px;
                }
            }
        }
    }
    .main_contact {
        width: 100%;
        padding: 0px 15px 0px 15px;
        position: relative;
        img {
            width: 100%;
            max-width: 100%;
            display: block;
        }
        span {
            position: absolute;
            bottom: 20px;
            left: 0;
            font-size: 24px;
            color: #fff;
            display: block;
            font-weight: bold;
            text-align: center;
            width: 100%;
        }
    }
}
.main_r {
    width: 74%;
}

.i_box1 {
    width: 100%;
    padding: 60px 0;
}
.box1_content {
    width: 100%;
    margin-top: 30px;
}
.box1_list {
    ul {
        li {
            width: 31.33%;
            margin-right: 3%;
            margin-bottom: 2%;
            float: left;
            box-shadow: 0px 1px 10px 1px #ddd;
            &:nth-child(3n) {
                margin-right: 0;
            }
            .imgauto {
                width: 100%;
                height: 209px;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                font-size: 14px;
                color: #333;
                display: block;
                text-align: center;
                margin: 6px 0;
                font-weight: bold;
            }
            &:hover {
                .imgauto img {
                    transform: scale(1.1);
                    transition: .5s;
                }
                span {
                    color: #0071bf;
                }

            }
        }
    }
}

.i_box2 {
    width: 100%;
    background: url(../img/casebg.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 40px 0;
    .box_tit {
        strong {
            color: #fff;
        }
        span {
            color: #fff;
        }
    }
    .box {
        width: 100%;
        margin-top: 50px;
        ul {
            li {
                width: 23%;
                margin-left: 2.66%;
                float: left;
                position: relative;
                .imgauto {
                    width: 100%;
                    height: 276px;
                    img {
                        transform: scale(1);
                        transition: .5s;
                    }
                }
                span {
                    font-size: 15px;
                    font-weight: bold;
                    color: #fff;
                    margin: 10px 0;
                    text-align: center;
                    display: block;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:hover {
                    .imgauto {
                        img {
                            transform: scale(1.1);
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}
.i_box3 {
    width: 100%;
    padding: 40px 0;
    margin-top: 30px;
    .box {
        width: 100%;
        margin-top: 20px;
    }
}
.box3_list {
    width: 100%;
    ul {
        li {
            width: 100%;
            float: left;
            margin-top: 30px;
            .fl {
                width: 50%;
                img {
                    display: block;
                    width: 100%;
                    max-width: 100%;
                }
            }
            .fr {
                width: 50%;
                span {
                    text-align: right;
                    font-size: 36px;
                    color: #0071be;
                    font-weight: bold;
                    display: block;
                    em {
                        font-family: Arial black;
                        font-size: 68px;
                        font-style: normal;
                        padding-left: 12px;
                    }
                }
                p {
                    display: block;
                    font-size: 15px;
                    text-align: right;
                    color: #555;
                    line-height: 48px;
                    margin-top: 15px;
                    padding-right: 25px;
                    background: url(../img/ysbg.jpg) no-repeat right 13px;
                }
            }
            &.on {
                .fl {
                    float: right;
                }
                .fr {
                    float: left;
                    span {
                        text-align: left;
                        em {
                            padding-left: 12px;
                        }
                    }
                    p {
                        text-align: left;
                        padding-left: 25px;
                        background: url(../img/ysbg.jpg) no-repeat left 13px;
                    }
                }
            }
        }
    }
}


.i_box4 {
    width: 100%;
    padding: 30px 0;
    margin-top: 40px;
    background: #f7f7f7;
    .list {
        width: 100%;
        margin-top: 30px;
        h3 {
            padding: 10px 0px 10px 0px;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
            i {
                font-style: normal;
                padding-bottom: 5px;
                background: url(../img/htit.jpg) left bottom no-repeat;
            }
        }
        .text1 {
            width: 50%;
            float: left;
            padding-right: 3%;
        }
        .text2 {
            width: 50%;
            float: left;
            padding-left: 3%;
        }
    }
    .hot {
        width: 100%;
        .fl {
            width: 45%;
            img {
                width: 94%;
                display: block;
            }
        }
        .fr {
            width: 55%;
            a {
                font-size: 16px;
                color: #666;
                display: block;
                font-weight: bold;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: #0071bf;
                }
            }
            span {
                font-size: 14px;
                color: #666;
                display: block;
                margin-top: 10px;
            }
            p {
                display: block;
                line-height: 24px;
                font-size: 14px;
                color: #666;
                margin-top: 10px;
            }
        }
    }
    .newhot_list {
        width: 100%;
        margin-top: 12px;
        ul {
            li {
                width: 100%;
                float: left;
                a {
                    font-size: 14px;
                    color: #333;
                    float: left;
                    max-width: 70%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 36px;
                    &:hover {
                        color: #0071bf;
                    }
                }
                span {
                    font-size: 14px;
                    color: #333;
                    float: right;
                    line-height: 36px;
                }
            }
        }
    }
    .que_list {
        width: 100%;
        ul {
            li {
                color: #545455;
                line-height: 21px;
                margin-bottom: 20px;
                a {
                    display: block;
                    background: url(../img/ask.jpg) no-repeat left center;
                    padding-left: 28px;
                    color: #333;
                    font-weight: bold;
                    font-size: 14px;
                }
                p {
                    background: url(../img/ans.jpg) no-repeat left 2px;
                    padding-left: 28px;
                    margin-top: 10px;
                    line-height: 22px;
                    color: #555;
                    font-size: 14px;
                }
            }
        }
    }
}
.i_box5 {
    width: 100%;
    margin-top: 50px;
    .box {
        width: 100%;
        margin-top: 30px;
        .fl {
            width: 50%;
            padding-right: 3%;
            h3 {
                font-family: Arial;
                color: #0071be;
                font-size: 54px;
                font-weight: bold;
                margin: 0px;
            }
            span {
                font-size: 16px;
                display: block;
                margin-bottom: 10px;
                color: #000;
                padding-bottom: 15px;
                border-bottom: 1px solid #dadada;
            }
            p {
                line-height: 30px;
                color: #555;
                margin-top: 10px;
                font-size: 14px;
            }
            a {
                display: block;
                text-align: center;
                width: 140px;
                padding: 7px 0px;
                color: #fff;
                text-decoration: none;
                border-radius: 15px;
                background: #0071bf;
                margin-top: 20px;
                transition: .5s;
                &:hover {
                    background: #02b29c;
                    transition: .5s;
                }
            }
        }
        .fr {
            width: 50%;
            padding-top: 15px;
            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.i_box6 {
    width: 100%;
    padding-top: 30px;
    margin-top: 70px;
    padding-bottom: 80px;
    .box {
        width: 100%;
        margin-top: 20px;
        ul {
            li {
                width: 22%;
                margin-right: 3%;
                margin-top: 3%;
                background: #fff;
                float: left;
                border: 1px solid #ddd;
                .imgauto {
                    width: 100%;
                    height: 140px;
                    padding: 10px;
                    img {
                        // -webkit-filter: grayscale(100%);
                        // -moz-filter: grayscale(100%);
                        // -ms-filter: grayscale(100%);
                        // -o-filter: grayscale(100%);
                        // filter: grayscale(100%);
                        // filter: gray;
                    }
                }
                &:nth-child(4n) {
                    margin-right: 0;
                }
                &:hover {
                    .imgauto {
                        img {
                            filter: inherit;
                        }
                    }

                }
            }
        }
    }
}
.footer {
    width: 100%;
    padding: 50px 0;
    background: url(../img/fbg.jpg) no-repeat top center;
    .foot_logo {
        width: 100%;
        width: 20%;
        float: left;
        span {
            padding-bottom: 20px;
            margin-bottom: 20px;
            display: block;
            border-bottom: 1px solid #4e97c5;
            a {
                img {
                    display: block;
                    max-width: 100%;
                }
            }
        }
        .copyright {
            width: 100%;
            p {
                font-size: 14px;
                color: #fff;
                display: block;
                line-height: 22px;
                a {
                    font-size: 14px;
                    color: #fff;
                }
            }
        }
    }
    .foot_contact {
        width: 26%;
        float: left;
        padding-left: 5%;
        p {
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            display: block;
            margin-bottom: 10px;
        }
    }
    .foot_nav {
        width: 35%;
        float: left;
        padding-left: 4%;
        ul {
            width: 33.33%;
            float: left;
            padding: 0 10px;
            li {
                margin-bottom: 10px;
                display: block;
                a {
                    font-size: 14px;
                    color: #fff;
                    display: block;
                    &:hover {
                        color: #97f3e7;
                    }
                }
                &:first-child {
                    a {
                        font-weight: bold;
                        &:hover {
                            color: #97f3e7;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .foot_ewm {
        width: 19%;
        float: left;
        img {
            background: #fff;
            padding: 10px;
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
        span {
            display: block;
            text-align: center;
            font-size: 14px;
            color: #fff;
            margin-top: 10px;
        }
    }
}
.link {
    background-color: #005188;
    padding: 20px 0px 20px 0px;
    text-align: center;
    color: #95abc1;
    font-size: 13px;
    .list {
        span {
            font-size: 14px;
        }
        a {
            font-size: 14px;
            padding: 0 10px 0 5px;
            &:hover {
                color: #97f3e7;
            }
        }
    }
}
.m_bottom {
    display: none;
    padding-bottom: 59px;
}
.m_fixedmenu {
    display: none;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #0071bf;
    padding: 5px 0;
    z-index: 110;
    a {
        width: 25%;
        float: left;
        img {
            height: 25px;
            display: block;
            margin: 0 auto;
        }
        span {
            display: block;
            text-align: center;
            font-size: 14px;
            color: #fff;
            margin-top: 5px;
        }
    }
}




.page_banner {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
    .pcimg {
        width: 1920px;
        height: 400px;
        position: relative;
        left: 50%;
        margin-left: -960px;
        z-index: 8;
    }
    .wapimg {
        display: none;
        max-width: 100%;
        height: auto;
    }
    .banner_text {
        position: absolute;
        width: 100%;
        left: 0;
        top: 15%;
        z-index: 9;
        strong {
            font-size: 60px;
            color: #333;
            line-height: 140%;
            display: block;
            position: relative;
            left: -50%;
            opacity: 0;
        }
        span {
            font-size: 18px;
            color: #333;
            line-height: 140%;
            display: block;
            position: relative;
            left: -50%;
            opacity: 0;
        }
        p {
            font-size: 14px;
            color: #666;
            display: block;
            line-height: 200%;
            max-width: 450px;
            margin-top: 30px;
            position: relative;
            left: -50%;
            opacity: 0;
        }
        a {
            width: 200px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            display: block;
            text-align: center;
            font-size: 16px;
            background: #0071bf;
            margin-top: 20px;
            position: relative;
            left: -50%;
            opacity: 0;
        }
    }
}

.wrap {
    width: 100%;
    margin: 10px 0;
    .main_l {
        background: none;
    }
}
.page_menu {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    h3 {
        display: block;
        line-height: 50px;
        padding-left: 20px;
        background: #0071bf;
        font-size: 24px;
        color: #fff;
        font-weight: normal;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    ul {
        width: 100%;
        display: block;
        border: 1px solid #eee;
        li {
            display: block;
            margin: 6px;
            box-shadow: 0px 1px 10px 1px #ddd;
            a {
                font-size: 14px;
                display: block;
                color: #333;
                padding: 8px 20px;
                transition: .3s;
                padding-left: 40px;
            }
            &:hover {
                a {
                    background: #02b29c;
                    color: #fff;
                    transition: .3s;
                }
            }
        }
    }
}
.currson {
    width: 100%;
    height: 40px;
    background: #f3f3f3;
    margin-bottom: 20px;
    p {
        line-height: 40px;
        span {
            border-left: 6px solid #02b29c;
            padding-left: 5px;
            font-size: 14px;
            line-height: 110%;
            @include inline-block();
        }
        a {
            @include inline-block();
            font-size: 14px;
            color: #666;
            line-height: 110%;
            &:hover {
                color: #02b29c;
            }
        }
    }
}

.new_list {
    width: 100%;
    margin-top: 30px;
    ul {
        li {
            width: 100%;
            padding: 15px;
            float: left;
            margin-bottom: 20px;
            padding-bottom: 20px;
            box-shadow: 0px 1px 10px 1px #ddd;
            .fl {
                width: 30%;
                .imgauto {
                    width: 100%;
                    height: 185px;
                }
            }
            .fr {
                width: 70%;
                padding-left: 20px;
                span {
                    font-size: 18px;
                    color: #000;
                    display: block;
                    padding-top: 8px;
                }
                p {
                    font-size: 14px;
                    color: #888;
                    display: block;
                    margin-top: 10px;
                    line-height: 24px;
                    height: 96px;
                    overflow: hidden;
                }
                
                .time {
                    padding-left: 20px;
                    font-size: 14px;
                    color: #333;
                    line-height: 30px;
                    background: url(../img/time.png) no-repeat left center;
                    background-size: 16px;
                    margin-top: 10px;
                }
            }
            &:hover {
                box-shadow: 0px 1px 10px 1px #0071bf;
                .fr {
                    span {
                        color: #0071bf;
                    }
                }
            }
        }
    }
}
.pro_list {
    margin-top: 12px;
}

.page_tit {
    width: 100%;
    border-bottom: 1px solid #ddd;
    span {
        font-size: 22px;
        color: #0071bf;
        font-weight: bold;
        float: left;
        margin-right: 20px;
        cursor: pointer;
        border-bottom: 3px solid #0071bf;
        padding-bottom: 12px;
        float: left;
    }
    p {
        float: right;
        font-size: 13px;
        color: #666;
        padding-left: 20px;
        line-height: 44px;
        a {
            font-size: 13px;
            color: #666;
            &:hover {
                color: #0071bf;
            }
        }
    }
}

.page_content {
    width: 100%;
    margin-top: 30px;
    .fl {
        width: 46%;
        img {
            display: block;
            max-width: 100%;
        }
    }
    .fr {
        width: 50%;
        span {
            font-size: 14px;
            color: #333;
            display: block;
        }
        input {
            outline: 0;
            display: block;
            padding: 0 10px;
            line-height: 40px;
            width: 100%;
            border: none;
            height: 40px;
            border: 1px solid #ddd;
            margin-top: 10px;
            margin-bottom: 20px;
            &:focus { 
                box-shadow: 0px 1px 10px 1px #02b29c;
            }
        }
        textarea {
            outline: 0;
            width: 100%;
            border: none;
            height: 100px;
            border: 1px solid #ddd;
            padding: 5px 10px;
            font-size: 14px;
            line-height: 24px;
            margin-top: 10px;
            &:focus { 
                box-shadow: 0px 1px 10px 1px #02b29c;
            }
        }
        .btnsub {
            background: #0071bf;
            border-radius: 5px;
            font-size: 14px;
            color: #fff;
            margin-top: 20px;
            text-align: center;
            &:focus { 
                box-shadow: 0px 1px 10px 1px #fff;
            }
        }
    }
}

.yunu-showcase {
  margin-bottom: 8px;
}

.yunu-showcase .yunupor {
  width: 100%;
  float: left;
}

.yunu-showcase .yunu-showcase-pic {
  position: relative;
  width: 100%;
}

.yunu-showcase .yunu-showcase-pic .cover {
  width: 100%;
  height: 320px;
  line-height: 400px;
  padding: 1px;
  overflow: hidden;
}

.yunu-showcase .yunu-showcase-pic .cover img {
    max-width: 100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
    display: block;
}

.yunu-showcase .prev {
  background: url(../img/prole.png) no-repeat 0 0;
  left: 0;
  position: absolute;
  width: 14px;
  height: 27px;
  bottom: 26px;
  z-index: 2;
}

.yunu-showcase .prev:hover {
  background-position: 0 bottom;
}

.yunu-showcase .next {
  background: url(../img/prori.png) no-repeat 0 0;
  right: 0;
  position: absolute;
  width: 14px;
  height: 27px;
  bottom: 26px;
  z-index: 2;
}

.yunu-showcase .next:hover {
  background-position: 0 bottom;
}

.yunu-showcase .showpropic {
  padding: 0 33px;
}

.yunu-showcase .showpropic .bd {
  width: 100%;
  font-size: 0;
  margin-top: 13px;
  overflow: hidden;
}

.yunu-showcase .showpropic .bd .picList li {
  cursor: pointer;
  float: left;
  width: 25%;
  overflow: hidden;
}
.yunu-showcase .showpropic .bd .picList li span {
  border: 1px solid #ddd;
  padding: 10px;
  display: block;
  margin: 2%;
  height: 55px;
}


.yunu-showcase .showpropic .bd .picList li.active span {
  border-color: #0071bf;
}

.yunu-showcase .showpropic .bd .picList li img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.prohot_new {
    width: 100%;
    padding: 6px;
    span {
        font-size: 22px;
        font-weight: normal;
        color: #545454;
        line-height: 40px;
        border-bottom: 1px solid #ebebeb;
        display: block;
        i {
             @include inline-block();
            height: 40px;
            border-bottom: 1px solid #02b29c;
            font-style: normal;
        }
    }
    ul {
        li {
            color: #545455;
            border-bottom: 1px dotted #dddddd;
            background: url(../img/li.png) no-repeat left 15px;
            line-height: 21px;
            padding: 10px 0px;
            padding-left: 14px;
            a {
                font-size: 14px;
                color: #333;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: #02b29c;
                }
            }
        }
    }
}








.iet {
    height: 30px;
    width: 100%;
    background: #fff3d7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
        line-height: 30px;
        a {
            color: #3aba50;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        right: 5%;
        top: 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        i {
            font-size: 12px;
            color: #333;
            padding-left: 5px;
            font-style: normal;
        }
    }
}

.content_tit {
    width: 100%;
    border-bottom: 1px dashed #e9e9e9;
    padding-bottom: 15px;
    h3 {
        display: block;
        text-align: center;
        font-size: 28px;
        color: #333;
        font-weight: bold;
        margin-top: 20px;
    }
    p {
        width: 100%;
        text-align: center;
        span {
            @include inline-block();
            line-height: 50px;
            margin-right: 10px;
            padding-left: 10px;
            margin-top: 10px;
            color: #5a5a5a;
            line-height: 100%;
            &:first-child {
                border-left: none;
            }
        }
    }
}
.content {
    width: 100%;
    padding: 0 1%;
    padding-bottom: 20px;
    p {
        font-size: 14px;
        color: #666;
        display: block;
        line-height: 24px;
        margin-top: 10px;
    }
    img {
        display: block;
        max-width: 100%;
    }
}
.show_pages {
    width: 100%;
    background: #f5f5f5;
    padding: 0 1%;
    p {
        font-size: 14px;
        color: #666;
        line-height: 50px;
        a {
            font-size: 14px;
            color: #666;
            line-height: 50px;
            &:hover {
                color: #0071bf;
            }
        }
    }
}
.show_text {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 10px;
    h3 {
        border-bottom: 1px solid #ddd;
        display: block;
        margin-bottom: 10px;
        span {
            width: 130px;
            height: 40px;
            display: block;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            color: #fff;
            background: #0071bf;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    ul {
        li {
            width: 49%;
            float: left;
            padding-right: 2%;
            padding-left: 15px;
            line-height: 30px;
            background: url(../img/dian2.png) no-repeat 5px center;
            a {
                font-size: 14px;
                color: #666;
                float: left;
                width: 60%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: #0071bf;
                }
            }
            span {
                float: right;
                font-size: 14px;
                color: #999;
            }
            &:nth-child(2n) {
                padding-right: 0;
                float: right;
            }
        }
    }
}


.content_box {
    width: 100%;
    margin-top: 20px;
    .fl {
        width: 50%;
        float: left;
        .pic {
            padding: 1%;
            border: 1px solid #ddd;
        }
    }
    .fr {
        width: 47%;
        float: left;
        padding-left: 3%;
        span {
            margin: 0px;
            padding-top: 5px;
            display: block;
            line-height: 22px;
            font-size: 18px;
            font-weight: bold;
            color: #000;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }
        p {
            line-height: 45px;
            height: 45px;
            border-bottom: 1px dashed #eee;
            font-size: 14px;
            color: #666;
            i {
                font-style: normal;
                font-size: 22px;
                color: #0071bf;
                font-family: impact;
            }
        }
        a {
            width: 145px;
            height: 40px;
            background: #0071bf;
            font-size: 16px;
            color: #fff;
            display: block;
            text-align: center;
            line-height: 40px;
            margin-top: 20px;
            border-radius: 5px;
        }
    }
}
.show_boxa {
    width: 100%;
    a {
        float: left;
        margin-right: 30px;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        &:hover {
            color: #0071bf;
        }
    }
}
.show_text2 {
    width: 100%;
    a {
        float: left;
        margin-right: 30px;
        font-size: 14px;
        color: #666;
        line-height: 30px;
        &:hover {
            color: #0071bf;
        }
    }
}
.hotpro_list {
    ul {
        li {
            width: 23%;
            margin-left: 2.66%;
            float: left;
            position: relative;
            box-shadow: 0px 1px 10px 1px #ddd;
            .imgauto {
                width: 100%;
                height: 208px;
                img {
                    transform: scale(1);
                    transition: .5s;
                }
            }
            span {
                font-size: 15px;
                font-weight: bold;
                color: #333;
                margin: 10px 0;
                text-align: center;
                display: block;
            }
            &:first-child {
                margin-left: 0;
            }
            &:hover {
                .imgauto {
                    img {
                        transform: scale(1.1);
                        transition: .5s;
                    }
                }
            }
        }
    }
}





@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
}

@media screen and (max-width: 1020px){
    .m_header {
        display: block;
    }
    .header {
        display: none;
    }
    .m_search {
        top: 80px;
    }
    .app_menu {
        top: 80px;
    }
    .m_header .m_head_content span {
        line-height: 80px;
    }
    .m_header .m_head_content {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo img {
        max-height: 100%;
    }
    .m_header .m_head_content p img {
        height: 30px;
        margin-top: 25px;
    }
    .m_header .m_head_content .menu {
        height: 70px;
        width: 70px;
        margin-top: 5px;
        margin-right: 10px;
    }
    .m_header .m_head_content .menu i {
        height: 70px;
        line-height: 70px;
    }
    .app_menu ul li a span {
        font-size: 14px;
        height: 42px;
        line-height: 42px;
    }
    .footer {
        padding: 70px 0;
    }
    .page_banner {
        height: auto;
    }
    .page_banner .pcimg {
        display: none;
    }
    .page_banner .wapimg {
        display: block;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .main_l {
        width: 100%;
    }
    .main_l .main_contact {
        display: none;
    }
    .main_r {
        width: 100%;
        margin-top: 10px;
        padding: 0 5px;
    }
    .page_menu h3 {
        background: url(../img/bottom.png) no-repeat 95% center #0071bf;
    }
    .page_menu h3.on {
        background: url(../img/right.png) no-repeat 95% center #0071bf;
    }
    .page_menu ul {
        display: none;
    }
    .page_banner .banner_text {
        display: none;
    }
}




@media screen and (max-width: 850px) {
    .i_box2 .box ul li {
        width: 48%;
        margin: 1%;
    }
    .i_box2 .box ul li:first-child {
        margin-left: 1%;
    }
    .i_box3 {
        display: none;
    }
    .i_box4 .list .text1 {
        width: 100%;
        padding: 0 1%;
    }
    .i_box4 .list .text2 {
        width: 100%;
        padding: 0 1%;
        margin-top: 20px;
    }
    .i_box5 .box .fl {
        width: 100%;
        padding: 0 1%;
    }
    .i_box5 .box .fr {
        width: 100%;
        margin-top: 10px;
    }
    .footer {
        padding: 30px 0;
        background: url(../img/fbg.jpg) no-repeat top center #076bb5;
    }
    .footer .foot_logo {
        width: 100%;
        padding: 0 1%;
    }
    .footer .foot_logo span {
        display: none;
    }
    .footer .foot_contact {
        width: 100%;
        padding: 0 1%;
        margin-top: 20px;
    }
    .footer .foot_nav {
        width: 100%;
        padding: 0 1%;
        margin-top: 20px;
    }
    .footer .foot_ewm {
        width: 100%;
        padding: 0 1%;
        margin-top: 10px;
    }
    .page_content {
        margin-top: 0;
    }
    .page_content .fl {
        display: none;
    }
    .page_content .fr {
        width: 100%;
    }
    .page_content .fr span {
        margin-top: 10px;
    }


}



@media screen and (max-width: 585px){
    .m_search {
        top: .36rem;
    }
    .app_menu {
        top: .36rem;
    }
    .m_header .m_head_content {
        height: .36rem;
    }
    .m_header .m_head_content span {
        line-height: .36rem;
    }
    .m_header .m_head_content p img {
        height: .16rem;
        margin-top: .1rem;
    }
    .m_header .m_head_content .menu {
        height: .36rem;
        width: .36rem;
        margin-top: 0;
    }
    .m_header .m_head_content .menu i {
        height: .36rem;
        line-height: .36rem;
    }
    .box_tit strong {
        font-size: 24px;
    }
    .box_tit img {
        max-width: 60%;
    }
    .box_tit span {
        font-size: 16px;
        margin-top: 5px;
    }
    .m_bottom {
        display: block;
    }
    .m_fixedmenu {
        display: block;
    }
    .content_box .fl {
        width: 100%;
    }
    .content_box .fr {
        width: 100%;
    }
    
}


@media screen and (max-width: 450px) {
    .i_box1 {
        padding: 15px 0;
    }
    .main_l .text ul {
        padding-bottom: 10px;
    }
    .main_l .text ul li {
        width: 48%;
        margin: 1%;
        float: left;
    }
    .box1_list ul li {
        width: 48%;
        margin: 1%;
    }
    .main_l .text ul li a {
        font-size: 14px;
    }
    .box1_list ul li:nth-child(3n) {
        margin-right: 1%;
    }
    .box1_list ul li .imgauto {
        height: 1.15rem;
    }
    .box1_list ul li:first-child +li +li +li +li +li +li {
        display: none;
    }
    .box1_list ul li:first-child +li +li +li +li +li +li +li {
        display: none;
    }
    .box1_list ul li:first-child +li +li +li +li +li +li +li +li {
        display: none;
    }
    .box1_content {
        margin-top: 15px;
    }
    .main_l .text {
        padding-top: 10px;
    }
    .main_l .text span {
        font-size: 21px;
        margin-bottom: 0px;
    }
    .main_l .text p {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .main_l .text ul {
        padding: 10px;
        padding-bottom: 4px;
    }
    .i_box2 {
        margin-top: 10px;
        padding: 20px 3%;
    }
    .i_box2 .box ul li:first-child {
        margin-left: 0;
    }
    .i_box2 .box ul li {
        width: 48%;
        margin: 0;
        margin-right: 4%;
        margin-top: 20px;
        padding-bottom: 0;
    }
    .i_box2 .box ul li:nth-child(2n) {
        margin-right: 0;
    }
    .i_box2 .box {
        margin: 0;
    }
    .i_box2 .box ul li .imgauto {
        width: 100%;
        height: 1.45rem;
    }
    .i_box4 .list h3 {
        text-align: center;
    }
    .i_box4 .hot .fr p {
        height: 72px;
        overflow: hidden;
    }
    .i_box4 .hot .fr span {
        font-size: 13px;
        color: #999;
        margin-top: 5px;
    }
    .i_box4 .hot .fr p {
        margin-top: 5px;
    }
    .i_box5 {
        margin-top: 20px;
    }
    .i_box5 .box .fl h3 {
        font-size: 36px;
    }
    .i_box6 {
        margin-top: 10px;
        padding-bottom: 20px;
    }
    .i_box6 .box ul li {
        width: 46%;
        margin: 2%;
    }
    .i_box6 .box ul li .imgauto {
        height: 100px;
    }
    .main_l {
        padding-bottom: 10px;
    }
    .page_tit span {
        font-size: 20px;
        padding-bottom: 10px;
    }
    .page_tit p {
        line-height: 40px;
    }
    .new_list {
        margin-top: 10px;
    }
    .new_list ul li {
        width: 98%;
        margin: 10px 1%;
        padding: 8px;
    }
    .new_list ul li .fl .imgauto {
        height: .8rem;
    }
    .new_list ul li .fl {
        width: 35%;
    }
    .new_list ul li .fr {
        width: 65%;
        padding-left: 10px;
    }
    .new_list ul li .fr span {
        padding-top: 0;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .new_list ul li .fr p {
        font-size: 13px;
        line-height: 22px;
        height: 44px;
        margin-top: 5px;
    }
    .new_list ul li .fr .time {
        margin-top: 5px;
        font-size: 12px;
        background-size: 14px;
        line-height: 22px;
    }
    .currson {
        display: none;
    }
    .wrap {
        margin-top: 0;
    }
    .page_tit {
        display: none;
    }
    .content_tit h3 {
        margin-top: 0;
    }
    .page_menu h3 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .show_pages .fl {
        width: 100%;
        line-height: 40px;
    }
    .show_pages .fr {
        width: 100%;
        padding-right: 0;
        padding-left: 0px;
        line-height: 40px;
        border-top: 1px solid #fff;
    }
    .show_pages p a {
        line-height: 40px;
    }
    .show_text ul li {
        width: 100%;
        padding-right: 5px;
    }
    .show_text ul li:nth-child(2n) {
        padding-right: 5px;
    }
    .yunu-showcase .showpropic .bd .picList li span {
        height: 50px;
        padding: 5px;
    }
    .hotpro_list ul li {
        width: 48%;
        margin: 1%;
    }
    .hotpro_list ul li .imgauto {
        height: 1.15rem;
    }
    .prohot_new {
        width: 98%;
        margin: 0 1%;
    }
    .i_box4 {
        margin-top: 0;
    }
}

@media screen and (max-width: 350px) {


}

/****************************** media ******************************/